#sumatra-mini-cart {
    background:#fff;
    color: #777777 !important;
    .sumatra-mini-cart-container {
        .sumatra-mini-cart-header {
            background-color: #000 !important;
            color: #ffffff !important;
            .sumatra-mini-cart-close {
                color: #FFF;
            }
        }
        .sumatra-classic-loader {
            border: 0;
            background-image: repeating-linear-gradient(-45deg, $color-primary, $color-primary 11px, #eee 10px, #eee 20px);
        }
        .sumatra-mini-cart-items {
            .sumatra-mini-cart-line-item {
                .sumatra-mini-cart-delete-item {
                    color: #ff0505;
                }
                .sumatra-mini-cart-name {
                    color: #0a0000 !important;
                }
                .sumatra-mini-cart-quantity-container {
                    border: none !important;
                    .sumatra-mini-cart-button {
                        height: 100%;
                        color: #000 !important;
                        background-color: #fff !important;
                    }
                }
                .sumatra-mini-cart-total {
                    //font-size:  !important;
                    color: #0a0a0a !important;
                }
            }
        }
        .sumatra-mini-cart-update-cart-row {
            .btn {

            }
        }
        .sumatra-mini-cart-footer {
            > .row {
                background-color: #efefef !important;
                &:nth-child(even) {
                    background-color: #ffffff !important;
                }
                #sumatra-mini-cart-apply-coupon {
                    margin-left: 15px;
                }
                .col-12 > input {
                    max-width:175px;
                    background-color: #fff;
                    @include screen(md){
                        max-width:100%;
                    }
                }
            }
        }
        .sumatra-mini-cart-sub-total-row {
            .sumatra-mini-cart-subtotal-label {
                //font-size: !important;
                color: #0a0202 !important;
            }
            .sumatra-mini-cart-subtotal {
                //font-size: !important;
                color: #ff0505 !important;
            }
        }
    }
}
#sumatra-mini-cart-preview-image{
    background:#FFF;
}