#payment{
    #paypal_pro_payflow-card-number{
        margin-right:0;
    }
    select{
        height:34px;
    }
}
.woocommerce {
    #ship-to-different-address {
        font-size:18px;
    }
}