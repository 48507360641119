// Define the breakpoints
$breakpoint-extra-small: 575px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1200px;


//sets pass in bootstrap class name to include that break point info
@mixin screen($size, $type: min, $pixels: $breakpoint-extra-small) {
    @if $size == 'col' {
        @media screen and (max + -width: $breakpoint-extra-small) {
            @content;
        }
    } @else if $size == 'sm' {
        @media screen and ($type + -width: $breakpoint-small) {
            @content;
        }
    } @else if $size == 'md' {
        @media screen and ($type + -width: $breakpoint-medium) {
            @content;
        }
    } @else if $size == 'lg' {
        @media screen and ($type + -width: $breakpoint-large) {
            @content;
        }
    } @else if $size == 'xl' {
        @media screen and ($type + -width: $breakpoint-extra-large) {
            @content;
        }
    } @else if $size == 'custom' {
        @media screen and ($type + -width: $pixels + px) {
            @content;
        }
    } @else {
        @content;
    }
}