body {
	@extend .font-base;
}

h1, h2, h3, h4, h5, h6 {
	@extend .font-secondary;
	text-transform: uppercase;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 20px;
}

.headline {

}

.subhead {
	font-size: 24px;
	font-weight: 800;
	font-style: normal;
	text-align: center;
	text-transform: uppercase;

	@include screen(lg) {
		font-size: 36px;
	}
}

.kicker {
	color: $color-fourth;
	font-size: 14px;
	font-weight: 300;
	font-style: normal;
	text-align: center;
	text-transform: uppercase;

	@include screen(lg) {
		font-size: 24px;
	}
}

#main-content-wrapper,
.wrapper {
	margin-top: 54px;
	@include screen(lg) {
		margin-top: 90px;
	}
}

.vcenter {
	display: inline-block;
	vertical-align: middle;
	float: none;
}

.products {
	.corner-ribbon {
		@extend .font-primary;
		font-size: 24px;
		line-height: 40px;
		font-weight: bold;

		&.top-right {
			top: 20px;
			background: #f26522;
		}
	}
}

.woocommerce-product-gallery__wrapper {
	overflow: hidden;
	position: relative;
}

.corner-ribbon {
	@extend .font-primary;
	font-size: 24px;
	line-height: 40px;
	font-weight: bold;

	&.top-right {
		top: 20px;
		background: #f26522;
	}
}


.container {
	max-width: 1200px;
}

figure {
	max-width: none;
}

@media only screen and (min-width: 768px) {
	h1 {
		font-size: 3.75vw;
	}
	h2 {
		font-size: 30px;
	}
}

/**--GLOBAL NAV**/

.navbar a {
	@include screen(lg) {
		font-size: 24px;
	}
}

.user-session {
	@extend .font-base;
}

a {
	color: $color-fourth
}

a:hover {
	color: $color-primary;
	text-decoration: none;
}

#error-404-wrapper {
	min-height: 400px;
}

a:hover {
	h1, h2, h3, h4, h5 {
		text-decoration: none;
	}
}