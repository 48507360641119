.vc-suma-timeline {
    padding: 50px 0;
    @include screen(md) {
        margin-bottom: -90px;
        padding-bottom: 0;
    }
    @include screen(xl) {
        padding: 50px 100px;
    }
    @media(min-width: 1400px) {
        padding: 50px 215px;
    }
    & > img {
        display: block;
        margin: auto;
    }
}
.suma-timeline {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.suma-timeline-title {
    color: #fff;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 0;
}
.suma-timeline-center {
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
}
.suma-timeline-bar {
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 46px;
    overflow: hidden;
    margin-top: 90px;
    background-color: #8c7e82;
    box-shadow: inset 0px 0px 7px -2px #000;
    @include screen(md) {
        margin-bottom: 140px;
    }
}

.suma-timeline-bar-fill {
    background-color: #8c7e82;
    position: absolute;
    left:0;
    right:0;
    top:0;
    height:100%;
}
.suma-timeline-state {
    position: relative;
    width:100%;
    @include screen(md) {
        &:nth-child(1) {
            top: 140px;
        }
        &:nth-child(3) {
            top: -140px;
        }
        &:nth-child(4) {
            top: -140px;
        }
    }
}
.suma-timeline-state::after {
    display:block;
    content: ' ';
    clear:both;
}
.suma-timeline-heading {
    background: linear-gradient(135deg, transparent 8px, #db2128 8px);
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    @extend .font-primary;
    font-size: 18px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: -47px;
}
.suma-timeline-icon {
    width: 0%;
    height: 2px;
    background-color: #8c7e82;
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: inherit;
    transform: translateY(-50%);
    color: #fff;
    transition: all .7s ease;
    @include screen(md) {
        top: 35%;
    }
}
.suma-timeline-icon:after {
    content: '';
    position: absolute;
    left: -7px;
    top: -5px;
    width: 15px;
    height:15px;
    border-radius: 100%;
    background-color: #db2128;
    border: 2px solid #8c7e82;
}
.suma-timeline-state:nth-child(2n+2) .suma-timeline-icon {
    left: 50%;
    margin-left: inherit;
}
.suma-timeline-state:nth-child(2n+2) .suma-timeline-icon:after {
    right: -7px;
    left: auto;
}
.suma-timeline-content {
    width: 40%;
    float: left;
    position: relative;
    margin-left: 100%;
    opacity: 0;
}
.suma-timeline-state:nth-child(2n+2) .suma-timeline-content {
    float:right;
    margin-right:100%;
}
.suma-timeline-state:last-child {
    .suma-timeline-icon {
        width: 0%;
        @include screen(md) {
            top: 50%;
        }
    }
}
.suma-timeline-content h4 {
    color: #8c7e82;
    font-size:26px;
    font-weight: normal;
    margin: 0;
    padding: 15px 0;
    text-align: left;
}


.suma-timeline-btn {
    font-size: 23px;
    margin-top: 0;
    @extend .font;
    width: 500px;
    max-width:100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &:hover {
        background: linear-gradient(135deg, transparent 8px, #656363 8px) !important;
        text-decoration: none;
        &:after {
            background-color: #656363 !important;
        }
    }
    &:after {
        right: 2%;
        font-size: 30px;
        @include screen(lg) {
            right: 20px;
        }
    }
}

/*
animations
*/
.suma-timeline-bar-fill,.suma-timeline-content,.suma-timeline-image {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    transition: all 500ms ease;
}

.suma-timeline-state:nth-child(2n+2).suma-timeline-animate .suma-timeline-content {
    margin-right:0%;
}

.suma-timeline-animate .suma-timeline-content {
    opacity:1;
    margin-left:0%;
}

.suma-timeline-animate .suma-timeline-icon {
    width: 18%;
}

.suma-timeline-animate:last-child {
    .suma-timeline-icon {
        width: 12%;
    }
}

/* mobile support */

@include screen(custom, max, 991) {
    .suma-timeline-btn {
        font-size: 18px;
    }
}

@include screen(custom, max, 767) {
    .suma-timeline {
        padding-top: 0;
    }
    .suma-timeline-state {
        padding-top: 48px;
        margin-top: 60px;
    }
    .suma-timeline-bar {
        margin-top: 0px;
        left:auto;
        right: 70px;
    }
    .suma-timeline-content {
        width: 100%;
        float: none;
    }
    .suma-timeline-content h4 {
        text-align: left;
        padding-right: 80px;
        font-size:20px;
    }
    .suma-timeline-state:nth-child(2n+2) .suma-timeline-content {
        margin-right: 0%;
        float: none;
    }
    .suma-timeline-state:nth-child(2n+2) .suma-timeline-icon {
        left: auto;
    }
    .suma-timeline-state:nth-child(2n+2) .suma-timeline-icon:after {
        right: auto;
        left: -10px;
    }
    .animate .suma-timeline-content {
        margin-left: 2%;
    }
    .suma-timeline-icon {
        left: auto;
        top: 0; right: 70px;
        transform: translateY(0);
        background-color: transparent;
        height: 70px;
        border-top: 2px solid #7e7175;
        border-left: 2px solid #7e7175;
        width: 0%;
    }
    .suma-timeline-icon:after {
        top: auto;
        bottom: -5px;
        left: -10px;
    }
    .suma-timeline-btn {
        width: 100%;
    }
}
