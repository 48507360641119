
.woocommerce table.wishlist_table {
	margin-bottom: 0;
	border: none;
	width: 100%;
	@include screen(md) {
		border: 1px solid rgba(0, 0, 0, .1);
	}
	thead {
		display: none;
		@include screen(md) {
			display: table-header-group;
		}
		th {
			border-top: none;
			@extend .font-secondary;
			font-size: 22px;
		}
	}
	tbody {
		tr {
			td {
				text-align: center;
				display: block;
				@extend .font-base;
				font-size: 20px;
				border-top: none;
				padding: 0;
				&:last-child {
					border-bottom: 1px solid rgba(0, 0, 0, .1);
					padding-bottom: 10px;
				}
				&.product-remove {
					padding: 0;
					position: absolute;
					right: 20px;
					text-align: center;
				}
				&.product-name {
					padding-top: 10px;
				}
				@include screen(md) {
					border-top: 1px solid rgba(0, 0, 0, .1);
					text-align: left;
					display: table-cell;
					padding: 16px 12px;
					&:last-child {
						border-bottom: none;
						padding-bottom: 0;
					}
					&.product-remove {
						padding: 16px 0;
						position: initial;
					}
					&.product-name {
						padding: 0;
					}
				}
			}
		}
	}
	tfoot {
		display: none;
	}
}
.woocommerce #content table.wishlist_table.cart a.remove {
	color: #212528;
	&:hover {
		color: $color-primary;
		background-color: transparent;
	}
}

.shop_table{
	.wishlist-in-stock{
		color:$color-primary!important;
	}
}