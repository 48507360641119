/*---MODULE-PROMO----*/
.vc_suma_single_promo {
    text-align: center;
    background-color: $color-primary;
    padding: 20px 40px;
    text-transform: uppercase;
    &.no-content {
        padding: 0;
    }
    .line-1-text {
        color: #fff;
        font-size: 18px;
    }
    .line-2-text {
        @extend .font-primary;
        font-size: 26px;
        line-height: 32px;
        @include screen(lg) {
            font-size: 36px;
            line-height: 35px;
        }
    }
    a {
        display: flex;
        justify-content: center;
        padding: 10px;
        .top-corner {
            border-top: 10px solid transparent;
            border-right: 11px solid $color-secondary;
            border-bottom: 25px solid $color-secondary;
            display: inline-block;
            vertical-align: bottom;
        }
        .fa-angle-right {
            padding: 0px 7px;
            color: $color-primary;
        }
    }
    .btn {
        background-color: $color-secondary;
        margin-top: 10px;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        color: #efebe0;
        padding-right: 37px;
        padding-left: 25px;
        &:hover {
            background: #000000 !important;
        }
        &:after {
            color: $color-primary !important;
            font-size: 17px;
        }
    }
}

