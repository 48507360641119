#suma-dealer-locator {
	.lookup {
		.row-1 {
			flex-wrap: nowrap;
			.address-column {
				@media(max-width: 767px) {
					display: flex;
				}
				.address-input {
					width: 100%;
				}
			}
			.btn-load {
				padding-right: 16px;
				padding: 9px 18px 10px 18px;
				border-radius: 0.25rem;
				&:after {
					display: none;
				}
			}
			.btn-geo-locate {
				background-color: #656363;
				display: inline-block !important;
				@media(max-width: 767px) {
					margin: 0 3px;
				}
			}
		}
	}
	.btn-geo-locate {
		padding: 9px 10px 10px 10px;
		border-radius: 0.25rem;
		&:after {
			display: none;
		}
	}
}
.page-id-1593 #page-wrapper {
	padding-bottom: 0;
}
.page-id-1593 .wpb_content_element {
	margin-bottom: 0 !important;
}
#suma-dealer-locator .results li {
	padding: 3px 0 3px 3px;
}
#suma-dealer-locator.layout-modern .lookup-form .results li>div {
	@include screen(lg) {
		width: 90%;
	}
}

@media(max-width: 991px) {
	#suma-dealer-locator.layout-modern .lookup-form {
		overflow-y:scroll;
	}
}

@media(min-width: 992px) {
	#suma-dealer-locator.layout-modern .lookup-form {
		top: 50%;
		transform: translateY(-50%);
	}
}