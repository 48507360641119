.aa-dropdown-menu{
    .autocomplete-header{
        display:none;
    }
}
body #algolia-search-box input{
    border-color:$color-primary;
}
body #algolia-search-box .search-icon{
    fill:$color-primary;
}
body #ais-wrapper{
    display:block;
    width: 100%;
}
