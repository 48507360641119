//.woocommerce-info {
//	border-top-color: $color-primary;
//	&:before {
//		color: $color-primary;
//	}
//}

//#woocommerce-wrapper {
//	padding-top: 0;
//	padding-bottom: 0 !important;
//	margin-top: 121px;
//	@include screen(xl) {
//		margin-top: 127px;
//	}
//}
.woocommerce ul.products  {
	margin-bottom: 0;
}
.woocommerce form .form-row-last, .woocommerce-page form .form-row-last,
.woocommerce form .form-row-first, .woocommerce-page form .form-row-first {
	display: block;
}

.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc {
	padding: 3px;
}

.woocommerce-checkout .checkout .payment-fixed {
	background-color: #ffffff;
}
