.suma-featured-section {
    padding: 40px 0;
    @include screen(md) {
        padding: 80px 0;
    }
    .wpb_column:nth-child(3) {
        .suma-featured-item {
            @include screen(lg) {
                margin-bottom: 0;
            }
        }
    }
    .wpb_column:last-child {
        .suma-featured-item {
            padding-bottom: 0;
            margin-bottom: 0;
            &:before,
            &:after {
                display: none;
            }
        }
    }
}
.suma-featured-item {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    @extend .font-base;
    color: black;
    font-size: 18px;
    &:after {
        content: '';
        display: block;
        width: 50px;
        height: 2px;
        background-color: #303030;
        margin: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    @include screen(lg) {
        padding-bottom: 0;
        &:after {display: none}

    }
    @include screen(xl) {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 0 !important;
    }
    .left-featured {
        width: 60%;
    }
    .right-featured {
        width: 40%;
        text-align: right;
        position:relative;
    }
    h3.line-1 {
        @extend .font-primary;
        text-transform: uppercase;
        font-size: 24px;
        white-space: nowrap;
        position: absolute;
        right: 0;
    }
    .line-2 {
        @extend .font-base;
        padding-left: 10px;
        line-height:18px;
        margin-top:37px;
        @include screen(lg) { padding-left: 0; }
    }
    .line-3 {
        color: $color-fourth;
        text-transform: uppercase;
        font-size:16px;
    }
    a {
        .top-corner {
            margin-left: 0;
            border-top: 10px solid transparent;
            border-right: 11px solid $color-primary;
            border-bottom: 25px solid $color-primary;
            display: inline-block;
            vertical-align: bottom;
        }
    }
}