.newsletter-form > div > input {
	margin-right: 20px;
	height: 40px;
}
.newsletter-form > div {
	margin-bottom: 10px;
}
.newsletter-form-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}
.newsletter-form-wrapper img {
	cursor: pointer;
	border: 2px solid transparent;
}
.newsletter-form-wrapper > div {
	width: calc(100% / 2);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	@include screen(md) {
		width: calc(100% / 3);
	}
}
.newsletter-form-wrapper {
	input[type="checkbox"] {
		visibility: hidden;
		&:checked {
			& + label img {
				border: 2px solid $color-primary;
			}
		}
	}
}
.newsletter-submit {
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
	@include screen(md) {
		flex-direction: row;
	}
}
.newsletter-submit input[type="submit"] {
	background: $color-primary;
}
.newsletter-submit input {
	margin-right: 0 !important;
	@media(max-width: 767px) {
		width: 80% !important;
	}
}
.newsletter-submit input[type="text"] {
	width: 300px;
	padding-left: 20px;
}
