.clearfix:after {
    content: "";
    display: table;
    clear: both;
}
/* CSS3 calc() function to perform calculations */
@mixin calc($property, $expression) {
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: -moz-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin diagonal() {
    overflow: hidden;
    z-index: 1;
    position: relative;

    &:before,
    &:after {
        position: absolute;
        content: ' ';
        width: 100%;
        left: 0;
        z-index: -1;
    }
    &:before {
        height: 20%;
        top: 0;
        transform: skew(-45deg);
        transform-origin: right bottom;
    }
    &:after {
        height: 83%;
        bottom: 0;
    }
}

//please don't create css names like this using a color or anything brand-specific
//think object/purpose oriented
@mixin grey-heading() {
    @extend .font-primary;
    font-size: 18px;
    @include diagonal();
    &:before,
    &:after {
        background: #58585a;
    }
}

@mixin cut-corner($color, $color-hover){

    background: linear-gradient(135deg, transparent 8px, $color 8px);
    &:hover {
        background: linear-gradient(135deg, transparent 8px, $color-hover 8px);
    }

}