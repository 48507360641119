.single-product.woocommerce {
    div.product {

        .suma-tabs {

            margin-top: 15px;

            @include screen(lg) {
                margin-top: 0;
            }

            .suma-tabs-triggers {

                padding-left:15px;

                .suma-tab {

                    background-color:$color-third;
                    display: inline-block;
                    padding:10px 20px;

                    &.tab-active {
                        background-color: #fff;
                        color: $color-secondary;
                        text-decoration: none;
                        font-weight: 400;
                    }
                }
            }
            .suma-tabs-panels {

                border: solid 5px #fff;

                .suma-tabs-panel {
                    display: none;

                    &.tab-panel-active {
                        display: block;
                    }
                }
            }
        }

        .suma-bar-chart {

            .suma-bar-row {

                margin-bottom: 5px;

                &.suma-footer-row .suma-row-label {
                    display: none;
                }

                @include screen(lg) {

                    display: flex;
                    margin-bottom: 0;

                    &.suma-footer-row .suma-row-label {
                        display: block;
                    }
                }

                .suma-row-label {

                    font-size:18px;

                    @include screen(lg) {
                        align-items: center;
                        border-bottom: solid 1px $color-fourth;
                        display:flex;
                        width:25%;
                    }
                }

                .suma-bar-wrap {

                    background-color: $color-fourth;
                    padding:2px;
                    background-repeat: repeat-y;
                    background-position: 0,20%,40%,60%,80%,100%;
                      background-image:url(/wp-content/themes/coyotelight/img/pixel-light-gray.gif),
                      url(/wp-content/themes/coyotelight/img/pixel-light-gray.gif),
                      url(/wp-content/themes/coyotelight/img/pixel-light-gray.gif),
                      url(/wp-content/themes/coyotelight/img/pixel-light-gray.gif),
                      url(/wp-content/themes/coyotelight/img/pixel-light-gray.gif),
                      url(/wp-content/themes/coyotelight/img/pixel-light-gray.gif);

                    @include screen(lg) {

                      background-position: 8px,20%,40%,60%,80%,calc(100% - 8px);

                      border-bottom: solid 1px $color-third;
                        padding:8px;
                        width:75%;
                    }

                    .suma-bar {

                        background-color: #fff;
                        padding:3px 0;
                        text-align: right;

                        .suma-bar-value {
                            color:#fff;
                            font-weight: normal;
                            padding:0 5px;

                        }

                      &.bar-light {
                        .suma-bar-value {
                          color:#000;
                        }
                      }
                    }
                }

                .suma-footer-wrap {
                    display: flex;
                    justify-content: space-between;

                    @include screen(lg) {
                        padding: 8px;
                        width:75%;
                    }
                }

                &:not(.suma-footer-row):last-child {
                    .suma-row-label {
                        border:none;
                    }
                }

            }

            .suma-footer-row .suma-row-label {
                border:none;
            }
        }

        #product-row {
            div.images {
                .flex-control-thumbs {
                    margin-top: 20px;
                    @include screen(lg) {
                        margin-top: 0;
                    }
                    li {
                        img {
                            //max-width: 92px;
                            margin: auto;
                        }
                    }
                }
                img {
                    width: auto;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
            .product_title {
                @extend .font;
                color: #000;
                font-weight:800;
                font-size:30px;
                text-align: center;

                @include screen(lg) {
                    font-size:36px;
                    text-align: left;
                }
            }
            .suma-woo-product-sku {
                color:$color-fourth;
                font-size:20px;
                margin-bottom:0;
                text-align: center;

                @include screen(lg) {
                    text-align: left;
                }
            }
            .woocommerce-product-rating {
                .woocommerce-review-link {
                    @extend .font;
                }
            }
            .price-row {
                display: flex;
                align-items: center;
                height: 50px;
                margin-top: 10px;
                .sumatra-product-price {
                    @extend .font;
                    color: $color-secondary;
                    font-size: 22px;
                    font-weight:800;
                    display: flex;
                    @include screen(md) {
                        font-size: 30px;
                        .price {
                            color: #656363 !important;
                        }
                    }

                  .price-prefix {
                    font-size: 50%;
                    font-weight: 300;
                    line-height: 26px;
                    margin-right: 2px;
                    align-self: flex-end;
                  }
                }
                .suma-woo-product-stock {
                    @extend .font-base;
                    color: $color-third;
                    font-size: 24px;
                    font-weight:800;
                    text-transform: uppercase;
                    text-align: right;

                    @include screen(lg) {
                        color: $color-sixth;
                    }
                }
            }
            .single-product-description {
                margin-top: 10px;
                .lead {
                    @extend .font-base;
                    color: #4f4c4d;
                    font-size: 18px;
                }
            }
            form.cart {

                div.quantity.buttons_added {

                    height:47px;

                    a.button {
                        height:100%;
                        width: 34px;
                        background-color: #fff;
                        color: $color-fourth;
                        font-size: 24px;

                        @include screen(xl){
                            width: 46px;
                        }
                    }
                    input[type="text"] {
                        background: #4f4c4d;
                        color: #ffffff;
                        height: 100%;
                        font-size: 20px;
                        line-height: 34px;
                        @extend .font-base;
                    }
                }
            }
            .single_add_to_cart_button {
                font-size: 20px;
                margin-left: 10px;
                padding: 10px 30px 10px 30px;
                line-height:28px;
                &:after {
                    //display: none;
                }

              &.disabled {
                background-color:#666;

                &:after {
                  color:#fff;
                }
              }
            }
        }
        #description-row {
            @extend .font-base;
            padding: 30px 15px;
            color: #FFF;
            background: #231f20;
            @include screen(lg) {
                background-image: url(/wp-content/uploads/sites/12/2019/01/coyote-glowing-green-coyote-light.jpg);
                background-position: left top;
                padding: 50px 15px;
            }
            @include screen(xl) {
                background-position: center top;
                padding: 80px 15px;
            }
            .kicker {
                text-align:left;
            }
            .subhead {
                color: $color-sixth;
                line-height: 1em;
                margin-bottom: 15px;
                text-align:left;
            }
            .text-standout-white {
                p {
                    @extend .font-base;
                    font-size: 16px;
                    margin-bottom: 15px;

                    @include screen(lg) {
                        font-size: 18px;
                        text-shadow: 0 0 5px rgba(0,0,0,1);
                    }

                    @include screen(xl) {
                        font-size: 20px;
                    }
                }
            }
            .features-item {
                margin-bottom: 0;
                .vc_row {
                    display: flex;
                    align-items: flex-end;
                    .section-title {
                        padding-left: 8px;
                        color: $color-primary;
                        font-size: 30px;
                        line-height: 25px;
                        text-transform: uppercase;
                        @extend .font-secondary;
                    }
                    .anchor-down {
                        text-align: right;
                        a {
                            font-size: 18px;
                            margin-top: 20px;
                            @include screen(md) {
                                margin-top: 0;
                            }
                            &:after {
                                color: $color-secondary;
                            }
                            &:hover {
                                background: linear-gradient(135deg, transparent 8px, $color-primary 8px);
                                color: $color-secondary;
                                &:after {
                                    color: $color-secondary;
                                    background-color: $color-primary;
                                }
                            }
                            span {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .shop_attributes {
                text-transform: uppercase;
                font-size: 22px;
                margin-bottom: 0;
                @extend .font-base;
                tr {
                    border-bottom: 1px solid #58585a;
                    th {
                        padding: 12px 8px;
                    }
                    td {
                        text-align: right;
                        color: #af9c86;
                        font-style: normal;
                    }
                }
            }
        }
        #runtime-row {
          margin-top:30px;
          margin-bottom:30px;

          @include screen(lg){
            margin-top:50px;
            margin-bottom:50px;
          }
        }
        #tab-row {
            padding: 45px 15px;
            background-color: #f6f6f6;
            @include screen(md) {
                background: #f6f6f6 url("/wp-content/uploads/sites/12/2019/02/bg-texture-light-gray.jpg") no-repeat center bottom;
                padding: 75px 15px 45px 15px;
            }
            & > .wpb_column {
                & > .vc_column-inner {
                    & > .wpb_wrapper {
                        .vc_tta-container {
                            margin-bottom: 0;
                        }
                        .vc_tta {
                            &:after {
                                content: " ";
                                display: table;
                                clear: both;
                            }
                        }
                    }
                }
            }
            .vc_tta-tabs-container {
                @include screen(md) {
                    float: left;
                    width: 17%;
                    position: relative;
                    padding-top: 35px;
                    margin-top: -35px;
                    margin-right: -5px;
                    &:before, &:after {
                        position: absolute;
                        content: ' ';
                        width: 100%;
                        left: 0px;
                        z-index: -1;
                    }

                    &:after {
                        height: 80%;
                        bottom: 0px;
                    }
                }
                @include screen(lg) {
                    &:before {
                        margin-left: 1px;
                    }
                }
                display: block;

                ul.vc_tta-tabs-list {
                    li.vc_tta-tab {
                        margin: 0;
                        display: block;
                        float: left;
                        text-align: center;
                        &:nth-child(2) {
                            width: 40%;
                        }
                        @include screen(md) {
                            display: block;
                            width: 100%;
                            float: none;
                            text-align: left;
                            position: relative;
                            &:after {
                                font-family: FontAwesome;
                                font-size: 30px;
                                display: none;
                                content: "\f105";
                                color: #000000;
                                position: absolute;
                                right: 17px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &:nth-child(2) {
                                width: 100%;
                            }
                        }

                        @include screen(lg) {
                            &:after {
                                display: inline-block;
                            }
                        }

                        a {
                            padding: 2px 5px;
                            background: transparent;
                            font-size: 18px;
                            text-transform: uppercase;
                            border: 0;
                            @extend .font-secondary;
                            @include screen(md) {
                                padding: 10px 20px;
                                border: 0;
                            }
                        }
                        &:last-child {
                            a {
                                @include screen(md) {
                                    border-bottom: 0;
                                }
                            }
                        }
                        &.vc_active {
                            a {

                                @include screen(md) {
                                    margin-right: 5px;
                                }
                                color: #000000;
                                background: #fff;
                            }
                        }
                    }
                }
            }
            .vc_tta-panels-container {
                @include screen(md) {
                    width: 83%;
                    border-left: 5px solid #000000;
                    border-bottom: 5px solid #000000;
                    float: left;
                }

                .vc_tta-panels {
                    @include screen(custom, max, 767) {
                        border: 0;
                        border-color: #58585a;
                        overflow-x: hidden;
                        &:before {
                            position: absolute;
                            left: 0;
                            right: 0;
                            content: " ";
                        }
                        &:before {
                            top: 1px;
                            height: 30px;
                            border-top: 5px solid #000000;
                        }

                    }
                    background: none;
                    border: 0;
                    .vc_tta-panel {

                        .vc_tta-panel-heading {
                            display: none;
                        }
                        .vc_tta-panel-body {
                            background: transparent;
                            border: 0;
                            @include screen(custom, max, 767) {
                                padding-top: 30px;
                                padding-left: 0;
                            }
                            .suma-specs {
                                ul {
                                    padding-left: 0;
                                    @include screen(md) {
                                        padding: 15px 0;
                                    }
                                    li {
                                        padding-left: 30px;
                                        list-style: none;
                                        position: relative;
                                        font-size: 16px;
                                        @extend .font-base;
                                        &:before {
                                            content: " ";
                                            position: absolute;
                                            top: 12px;
                                            left: 0;
                                            background: $color-sixth;
                                            border: 0;
                                            width: 14px;
                                            list-style: none;
                                            height: 6px;
                                            margin-right: 20px;
                                        }

                                        @include screen(lg) {
                                            font-size: 20px;
                                        }
                                    }
                                }
                            }
                            #reviews {
                                ol.commentlist {
                                    padding: 0;

                                    li {
                                        &:nth-child(even) {
                                            clear: right;
                                        }
                                        &:nth-child(odd) {
                                            clear: left;
                                        }
                                        .star-rating {
                                            height: 1.78em;
                                            width: 8.7em;
                                            &:before, & > span:before {
                                                color: #ffffff;
                                                content: "\f005\f005\f005\f005\f005";
                                                font-family: FontAwesome;
                                                font-size: 30px;
                                            }
                                            > span:before {
                                                color: $color-primary;
                                            }
                                        }
                                        .comment_container {
                                            border: 0;
                                            padding-left: 0;
                                            .comment-text {
                                                margin: 0;
                                                border: 0;
                                                padding: 0;
                                                .meta {

                                                    @extend .font-base;
                                                    font-size: 18px;
                                                    font-weight: 400;
                                                    strong {
                                                        margin-right: 5px;
                                                        @extend .font-base;
                                                        font-size: 18px;
                                                        font-weight: 400;
                                                    }
                                                }
                                                .description {
                                                    font-size: 18px;
                                                    @extend .font-base;
                                                    .ywar-review-thumbnails {
                                                        background: #ebebeb;
                                                        border: 1px dashed #8c8968;
                                                    }
                                                    .ywar_thumbnail {
                                                        margin-left: 15px;
                                                    }
                                                    .review_helpful {
                                                        vertical-align: top;
                                                    }
                                                    .review_title {
                                                        color: #000;
                                                        font-size: 22px;
                                                        font-weight: 400;
                                                    }
                                                    .button {
                                                        &:hover {
                                                            background: #000;
                                                            color: #FFF;
                                                        }
                                                        &:focus {
                                                            box-shadow: none;
                                                        }

                                                        @extend .btn-info;
                                                        @extend .btn;
                                                        min-width: 120px !important;
                                                        padding-top: 0.25rem;
                                                        padding-bottom: 0.25rem;
                                                        color: #000;
                                                        font-size: 16px;
                                                        border: 1px solid
                                                    }
                                                    .ywar_review_helpful {
                                                        font-size: 18px;
                                                    }
                                                    .reply {
                                                        .open-reply,
                                                        .stop-reply,
                                                        .comment-reply-link,
                                                        .comment-edit-link {
                                                            @extend .btn.btn-primary;
                                                        }
                                                        .open-reply {
                                                            margin-right: 5px;
                                                        }
                                                    }
                                                    .review_vote {
                                                        a {
                                                            background-color:#fff !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        @include screen(lg) {
                                            width: 50%;
                                            float: left;
                                            display: block;
                                        }
                                    }
                                }
                                a.ywar_show_more {
                                    @extend .btn;
                                    @extend .btn-primary;
                                    font-size: 22px;
                                    padding-left: 50px;
                                    padding-right: 50px;
                                }
                                #review_form_wrapper {
                                    #reply-title {
                                        display: block;
                                        border-bottom: 8px solid #000000;
                                        margin-bottom: 20px;
                                        .review_label {
                                            padding: 10px 25px;
                                            display: inline-flex;
                                            margin-bottom: 2px;
                                            @extend .font-primary;

                                            font-size: 18px;
                                            &:before,
                                            &:after {
                                                background: #ffffff;
                                            }
                                        }
                                    }
                                    #reply-title:after,
                                    #reply-title:before {
                                    }

                                    #submit {
                                        @extend .btn.btn-primary;
                                        padding: 8px 17px;
                                        font-size: 16px !important;
                                        font-weight: 300;
                                    }
                                    label {
                                        font-size: 18px;
                                    }

                                    .ywar-upload-limitation {
                                        font-size: 14px;
                                    }
                                    #title {
                                        width: 100%;
                                    }
                                    #respond {
                                        display: block;
                                        margin-left: 15px;
                                        .stars {
                                            a {
                                                width: 30px;
                                                height: 30px;
                                                &:before {
                                                    font-family: FontAwesome;
                                                    content: '\f005';
                                                    color: $color-fourth;
                                                    font-size: 30px;
                                                }
                                                &.active:before {
                                                    font-family: FontAwesome;
                                                    content: '\f005';
                                                    color: $color-primary;
                                                }
                                            }
                                        }
                                        .stars.selected {
                                            a.active ~ a::before {
                                                font-family: FontAwesome;
                                                content: '\f005';
                                                color: #ffffff;
                                            }
                                            a:not(.active)::before {
                                                font-family: FontAwesome;
                                                content: '\f005';
                                                color: $color-primary;
                                            }
                                        }
                                        input[type="text"], textarea {
                                            background: #ebebeb;
                                            padding-left: 10px;
                                            padding-top: 5px;
                                            padding-right: 10px;
                                            padding-bottom: 5px;
                                            border: 2px solid black;
                                            max-width: 100%;
                                        }
                                        #do_uploadFile {
                                            font-size: 14px !important;
                                            color: black !important;
                                            padding: 5px 30px;
                                            max-width: 200px;
                                        }
                                    }
                                }
                            }
                            #ywar_reviews {
                                #reviews_summary {
                                    max-width: 600px;
                                    text-align: center;
                                    margin: 0 auto;
                                    .overall-rating {
                                        font-size: 30px;
                                        @extend .font-secondary;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
        #related-products {
            padding: 50px 0;s
            .related-title {
                margin-bottom: 80px;
                h2 {
                    border-bottom: 8px solid #000000;
                    margin-bottom: 0;
                    span {
                        padding: 10px 20px;
                        background-color: $color-primary;
                        @extend .font-primary;
                        color: #FFF;
                        font-size: 18px;
                        display: inline-block;
                        margin-bottom: 2px;
                    }
                }
            }
            .related {
                & > h2 {
                    display: none;
                }
                li.product {
                    width: 33%;
                    margin: 0;
                    text-align: center;

                    a {
                        display: block;
                        position: relative;
                        overflow: hidden;
                    }

                    @include screen(custom, max, 767) {
                        width: 100%;
                        margin: 0;
                        float: none;
                        margin-bottom: 30px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                    a {
                        img {
                            max-width: 250px;
                            width: auto !important;
                            margin: 0 auto;
                        }
                        h2 {
                            font-size: 24px;
                            color: #000;
                            @extend .font-base;
                        }
                        .price {
                            span {
                                @extend .font-primary;
                                font-size: 24px;
                                color: $color-fourth;
                            }
                        }
                        .btn {
                            span {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ywar_stars_value {
    font-weight: normal;
}

.woocommerce div.product form.cart div.quantity {
    display: flex;
    align-items: center;
}

.woocommerce div.product form.cart .button {
    padding: 0;
}

.single-product.woocommerce div.product #product-row form.cart div.quantity.buttons_added a.button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.single_variation_wrap {
    width: 100%;
}

.single-product div.product form.cart .variations label {
    @extend .font;
    font-size:20px;
    white-space: nowrap;
    vertical-align: middle;
}

.single-product div.product form.cart .variations .value select {
    @extend .font;
    padding-left: 10px;
}

@media(min-width: 1000px) {
    .single-product div.product form.cart .variations .value select {
        width: 96%;
    }
}

.single-product div.product .star-rating span {
    color: $color-fifth;
}

//Thumbnail slider

.woocommerce-product-gallery {
    .flex-control-nav {
        &.slick-slider {
            background: transparent;
            @media(min-width: 1000px) {
                display: flex;
                flex-direction: column;
            }
            @media(max-width: 1000px) {
                .slick-track {
                    display: flex;
                }
            }
            .slick-arrow {
                cursor: pointer;
            }
        }
    }
}

.product-type-grouped {
    #product-row {
        form.cart {
            .single_add_to_cart_button{
                margin-left:0!important;
                margin-top:16px;
                width:100%;
            }
            .woocommerce-grouped-product-list{
                width:100%;
            }
            div.quantity.buttons_added {
                min-width: 125px;
                width: 100%;
            }
            .woocommerce-grouped-product-list-item__price{
                @extend .font;
                color: rgb(107, 97, 100);
            }
        }
    }
}

//Swatches
.woo-variation-swatches-stylesheet-enabled .variable-items-wrapper {
    flex-wrap: nowrap !important;
}