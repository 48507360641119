
.logo-grid{
  background:#000;
  padding:2% 15px;
  .subheader{
    padding:5% 0;
    width:100%;
    text-align: center;
    color:#fff;
    @include screen(lg){
      padding:2% 0;
    }
  }
  .logo{
    display:flex;
    align-items:flex-end;
    text-align: center;
    padding:1%;
    margin:5% 0;
    filter: unquote("grayscale(100%)");
    @include screen(sm){
      margin:3% 0;
    }
    @include screen(md){
      margin: 1% 0;

    }
    &:hover{
      filter: unquote("grayscale(0%)");
    }
  }
}

