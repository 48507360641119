.marketing-section {
	padding: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 40px 0 40px 0;
	@include screen(lg) {
		padding: 100px 100px 80px 100px !important;
	}
	.wpb_raw_html {
		margin-bottom: 0;
	}
	.subhead {
		font-size: 24px;
		@extend .font-primary;
		padding-bottom: 10px;
		border-bottom: 4px solid $color-primary;
		text-shadow: 0px 0px 8px black;
		color: #fff;
		@include screen(md) {
			font-size: 37px;
		}
		@include screen(lg) {
			padding-bottom: 20px;
		}
	}
	p {
		color: #ffffff;
		@extend .font-base;
		font-size: 18px;
		margin-top: 10px;
		text-transform: uppercase;
		@include screen(md) {
			font-size: 30px;
		}
	}
	.btn {
		@extend .font-base;
		font-size: 18px;
		color: #FFF;
		padding-right: 38px;
		@include screen(lg) {
			font-size: 30px;
		}
		@include screen(xl) {
			font-size: 34px;
		}
	}
	.vc_column-inner {
		padding: 0 !important;
	}
}

.marketing-section-2 {
	background-position: center;
	@include screen(lg) {
		padding: 138px 100px 104px 100px !important;
	}
	.marketing-col-right {
		max-width: 700px;
		margin: auto;
		@include screen(lg) {
			max-width: none;
			padding: 0;
		}
	}
}
.marketing-col-left {
	max-width: 700px;
	margin: auto;
	z-index: 1;
	@include screen(lg) {
		max-width: none;
		padding: 0;
		& > img {
			display: block !important;
		}
	}
	& > img {
		display: none;
	}
}
.marketing-row {
	margin: 0;
}

.test-thing-2,
.test-thing {
	position: relative;
}
#trigger4,
#trigger42 {
	position: relative;
	top: -365px;
}