.woocommerce-MyAccount-content {
	margin-top: 20px;
	@include screen(md) {
		margin-top: 0;
	}
}

.woocommerce table.shop_table th {
	@extend .font-secondary;
	font-size: 22px;
	@include screen(md) {
		min-width: 140px;
	}
}

.account-orders-table {
	tbody {
		tr {
			background-color: transparent !important;
			td {
				@extend .font-base;
				font-size: 18px;
			}
		}
	}
}

.edit.btn.btn-primary {
	margin-top: 0;
}

.woocommerce-orders-table__cell {
	&.woocommerce-orders-table__cell-order-actions {
		display: flex;
		justify-content: flex-end;
		@include screen(md) {
			justify-content: space-between;
		}
		a {
			margin-top: 0;
			padding: 10px 20px 10px 16px;
			margin-left: 5px;
			&:after {
				right: 6px;
			}
			@include screen(md) {
				margin-left: 0;
			}
			&:first-child {
				margin-left: 0;
			}
		}
	}
}
.woocommerce-orders-table__header {
	@include screen(md) {
		min-width: 100px !important;
	}
}
.woocommerce-customer-details {
	& > section {
		& > .woocommerce-column {
			max-width: none;
			&.col-2 {
				margin-top: 20px;
				@include screen(lg) {
					margin-top: 0;
				}
			}
		}
	}
}

@include screen(custom, max, 800) {
	.woocommerce-order-overview.woocommerce-thankyou-order-details {
		margin-bottom: 20px !important;
		li {
			border-right: none !important;
			float: none !important;
			margin-bottom: 10px;
		}
	}
}