/*---MODULE-PROMO----*/
.vc_suma_single_promo_3 {
	text-align: center;
	background-color: $color-third;
	padding: 40px 10px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	&:after {
		content: ".";
		visibility: hidden;
		display: block;
		height: 0;
		clear: both;
	}
	@include screen(lg){
		padding: 20px 80px;
	}
	@include screen(xl) {
		flex-direction: row;
	}
	&.no-content {
		padding: 0;
	}
	.line-1-text {
		font-size: 20px;
		color: #fff;
		@extend .font-base;
		@include screen(xl) {
			font-size: 22px;
			color: #212529;
			margin-right: 10px;
			text-align: left;
		}
	}
	.line-2-text {
		@extend .font-primary;
		font-size: 23px;
		line-height: 32px;
		flex-grow: 1;
		@include screen(xl) {
			font-size: 22px;
			text-align: left;
		}
	}
	.btn {
		padding: 10px 38px;
		@extend .font-base;
		font-size: 18px;
		margin-top: 10px;
		&:after {
			display: none;
		}
		@include screen(lg) {
			font-size: 28px;
			padding: 16px 88px;
		}
		@include screen(xl) {
			margin-top: 0;
		}
	}
}